import {
  PauseCircle,
  PauseCircleRounded,
  PauseRounded,
  PlayArrowRounded,
  PlayCircle,
  PlayCircleRounded,
} from "@mui/icons-material";
import {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  MutableRefObject,
} from "react";
import ReactPlayer from "react-player";
import { COLOR_BLACK, COLOR_MAIN, COLOR_WHITE } from "../../data/colors/Colors";
import { CustomCardView } from "./CustomCardView";
import { Box, IconButton, Slider, Stack } from "@mui/material";
import { CustomTextView } from "./CustomTextView";

interface p {
  url: string | undefined;
  color?: string;
  bgColor?: string;
  fontSize?: number;
  iconSize?: number;
}

const Player = ({
  url,
  color = COLOR_WHITE,
  bgColor = COLOR_BLACK,
  fontSize = 16,
  iconSize = 26,
}: p) => {
  const [playing, setPlaying] = useState(false);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const playerRef = useRef() as MutableRefObject<ReactPlayer>;
  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        controls={false}
        playing={playing}
        width={"100%"}
        height={0}
        style={{ background: COLOR_MAIN }}
        url={url}
        onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
        onSeek={setPlayedSeconds}
        onDuration={setDurationSeconds}
      />
      <Controls
        playerRef={playerRef}
        bgColor={bgColor}
        color={color}
        iconSize={iconSize}
        fontSize={fontSize}
        playing={playing}
        setPlaying={setPlaying}
        playedSeconds={playedSeconds}
        duration={durationSeconds}
      />
    </div>
  );
};

function convertSecondstoTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;

  const timeString =
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return timeString;
}

type ControlsProps = {
  playing: boolean;
  setPlaying: Dispatch<SetStateAction<boolean>>;
  playedSeconds: number;
  duration: number;
  playerRef: MutableRefObject<ReactPlayer>;
  color: string;
  bgColor: string;
  fontSize: number;
  iconSize: number;
};

const Controls = (props: ControlsProps) => {
  const seek = (event: Event, newValue: number | number[]) => {
    props.playerRef.current.seekTo(newValue as number, "seconds");
  };

  return (
    <CustomCardView
      borderRadius={44}
      background={props.bgColor}
      padding={0}
      children={
        <Stack
          direction={"row"}
          alignItems={"center"}
          pl={1}
          pr={2}
          spacing={2}
        >
          <Stack direction={"row"} alignItems={"center"} flex={1}>
            <IconButton onClick={() => props.setPlaying(!props.playing)}>
              {props.playing ? (
                <PauseRounded
                  sx={{ color: props.color, fontSize: props.iconSize }}
                />
              ) : (
                <PlayArrowRounded
                  sx={{ color: props.color, fontSize: props.iconSize }}
                />
              )}
            </IconButton>
            <CustomTextView
              text={`${convertSecondstoTime(
                Number(props.playedSeconds.toFixed(0))
              )}/${convertSecondstoTime(Number(props.duration.toFixed(0)))}`}
              textColor={props.color}
              fontSize={props.fontSize}
            />
          </Stack>

          <Stack flex={2} alignItems={"center"}>
            <Slider
              value={props.playedSeconds}
              onChange={seek}
              color={"info"}
              min={0}
              max={props.duration}
              sx={(t) => ({
                color:
                  props.color == COLOR_WHITE
                    ? "rgba(255,255,255,0.87)"
                    : "rgba(0,0,0,0.87)",
                "& .MuiSlider-track": {
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  width: 10,
                  height: 10,
                  backgroundColor: props.color,
                  "&::before": {
                    boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                  },
                  "&:hover, &.Mui-focusVisible, &.Mui-active": {
                    boxShadow: "none",
                  },
                },
                ...t.applyStyles("dark", {
                  color: props.color,
                }),
              })}
            />
          </Stack>
        </Stack>
      }
    />
  );
};

export default Player;
