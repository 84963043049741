import { EventRepository } from "../../../../data/repository/event/EventRepository";
import { Event } from "../../../model/Event";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetEventUseCase {
  invoke: (id: string) => Promise<ResponseState<Event>>;
}

export class GetEventUseCaseImpl implements GetEventUseCase {
  private eventRepo: EventRepository;

  constructor(eventRepo: EventRepository) {
    this.eventRepo = eventRepo;
  }

  async invoke(id: string): Promise<ResponseState<Event>> {
    try {
      let response = await this.eventRepo.getOne(id);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
