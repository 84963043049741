export const STORAGE_KEYS = {
  TOKEN: "firetek-audio-sync-token",
  ROLE: "user-role"
};

export const MAIN_FONT_FAMILY = "Outfit";

export const LOGIN_PAGE_BACKGROUND_IMAGE = require("../../assets/images/login_background_image.jpg");
export const SIGNUP_PAGE_BACKGROUND_IMAGE = require("../../assets/images/signup_background_image.jpg");

export const ADMIN_PANEL_EMAIL = "admin@firetek.com";
export const ADMIN_PANEL_PASSWORD = "!Password123";

export const GOOGLE_MAPS_API_KEY = "AIzaSyCJbF_ZYARirBt-ufNlvHtfSWgoETxZhhg";

export const MAP_DARK_STYLE = [
  {
    stylers: [
      {
        hue: "#ff1a00",
      },
      {
        invert_lightness: true,
      },
      {
        saturation: -100,
      },
      {
        lightness: 33,
      },
      {
        gamma: 0.5,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#2D333C",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#e79f39",
      },
    ],
  },
];
