import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { EventsViewModel } from "./EventsViewModel";
import {
  provideCreateEventUseCase,
  provideDeleteEventUseCase,
  provideGetAllEventsUseCase,
  provideGetCompanyDetailsUseCase,
} from "../../di/UseCasesModule";
import { CustomTextView } from "../../component/CustomTextView";
import { STRINGS_EVENTS } from "../../../data/strings/Strings";
import {
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { AddRounded } from "@mui/icons-material";
import { EmptyView } from "../../component/EmptyView";
import {
  ROUTE_ENDPOINTS,
  ROUTE_NEW,
} from "../../../data/constant/RouteConstants";
import { EventsListItemView } from "./EventsListItemView";
import { LoadingView } from "../../component/LoadingView";

import StackGrid, { transitions } from "react-stack-grid";
import { CustomAlertDialog } from "../../component/CustomAlertDialog";
import { useState } from "react";

const { flip } = transitions;

export function EventsView() {
  const viewModel = EventsViewModel(
    provideGetCompanyDetailsUseCase(),
    provideGetAllEventsUseCase(),
    provideDeleteEventUseCase()
  );

  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
  return (
    <Box maxWidth={"100%"}>
      <Backdrop
        sx={{ color: COLOR_WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewModel.isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CustomAlertDialog
        title={"Delete Event"}
        description={"Are you sure you want to delete this event?"}
        open={showDeleteAlertDialog}
        handleNegativeButtonClick={() => setShowDeleteAlertDialog(false)}
        handlePositiveButtonClick={() => {
          viewModel.deleteEvent();
          setShowDeleteAlertDialog(false);
        }}
      />
      <Stack p={3}>
        <Stack
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
          pb={2}
        >
          <CustomTextView
            text={STRINGS_EVENTS}
            variant="h4"
            fontWeight="bold"
            textColor={COLOR_MAIN}
          />
          {viewModel.createEventCheckingLoading ? (
            <CircularProgress sx={{ color: COLOR_MAIN }} />
          ) : (
            <IconButton
              LinkComponent={"a"}
              href={`${ROUTE_ENDPOINTS.HOME_EVENTS}/${ROUTE_NEW}`}
              sx={{ background: COLOR_MAIN }}
              disabled={!viewModel.canCreateEvent}
            >
              <AddRounded
                sx={{
                  color: viewModel.canCreateEvent
                    ? COLOR_WHITE
                    : COLOR_LESS_BLACK,
                }}
                fontSize="large"
              />
            </IconButton>
          )}
        </Stack>
        <>
          {viewModel.isLoading && viewModel.events?.length === 0 ? (
            <LoadingView />
          ) : (
            <>
              {viewModel.events && (
                <>
                  {viewModel.events.length === 0 ? (
                    <EmptyView />
                  ) : (
                    <Grid container spacing={2}>
                      {viewModel.events.map((event, index) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            key={event?._id}
                          >
                            <EventsListItemView
                              event={event}
                              key={event._id}
                              onDeleteClicked={(eventId) => {
                                viewModel.setSelectedEventId(eventId);
                                setShowDeleteAlertDialog(true);
                              }}
                            />
                          </Grid>
                        );
                      })}
                      <Grid
                        width={"98vw"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        display={"flex"}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                      {viewModel.isLoading ? (
                        <LoadingView minHeight="5vh" />
                      ) : (
                        <Stack
                          alignItems={"center"}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          {viewModel.showLoadMoreButton && (
                            <Button
                              variant="outlined"
                              sx={{
                                borderRadius: 22,
                                textTransform: "none",
                                borderColor: COLOR_MAIN,
                                background: COLOR_MAIN,
                                "&:hover": {
                                  borderColor: COLOR_MAIN,
                                  background: COLOR_LIGHT_GRAY,
                                },
                              }}
                              onClick={viewModel.getAllEvents}
                            >
                              <CustomTextView
                                text={"Load More"}
                                fontSize={20}
                                fontWeight="bold"
                              />
                            </Button>
                          )}
                        </Stack>
                      )}</Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Stack>
    </Box>
  );
}
