import { useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_ENDPOINTS } from "../../../../data/constant/RouteConstants";
import { SignupUseCase } from "../../../../domain/usecase/auth/signup/SignupUseCase";
import { Role } from "../../../../domain/model/Auth";
import { State } from "../../../../domain/model/ResponseState";
import { useSnackbar } from "notistack";
import { CreateCompanyUseCase } from "../../../../domain/usecase/company/create/CreateUseCase";
import {
  Company,
  CreateCompanyRequestModel,
} from "../../../../domain/model/Company";
import { FileModel } from "../../../../domain/model/File";
import { UploadFileUseCase } from "../../../../domain/usecase/uploadFile/UploadFileUseCase";
import { uploadFile } from "../../../../core/utils/Utils";

export function SignupViewModel(
  signupUseCase: SignupUseCase,
  createCompanyUseCase: CreateCompanyUseCase,
  uploadFileUseCase: UploadFileUseCase
) {
  const [imagesFiles, setImagesFile] = useState<Array<File | null>>([
    null,
    null,
    null,
  ]);

  const [companyInfo, setCompanyInfo] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userId, setUserId] = useState<string | undefined>();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  function doSignupProcess() {
    if (userId) {
      createCompany(userId);
    } else {
      signup();
    }
  }

  async function signup() {
    setIsLoading(true);
    const response = await signupUseCase.invoke({
      email,
      password,
      role: Role.COMPANY,
    });
    switch (response.responseState) {
      case State.Success:
        const userId = response.data?.id;
        setUserId(userId);
        if (userId) createCompany(userId);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        setIsLoading(false);
        break;
    }
  }

  async function createCompany(userId: string) {
    setIsLoading(true);

    const presentationPictures: Array<string> = [];
    await Promise.all(
      imagesFiles.map(async (file) => {
        if (file) {
          const uploadedFile = await uploadFile(uploadFileUseCase, file);
          if (uploadedFile?._id) presentationPictures.push(uploadedFile._id);
        }
      })
    );
    const company: CreateCompanyRequestModel = {
      userId: userId,
      companyName,
      companyInfo,
      presentationPictures:
        presentationPictures.length > 0 ? presentationPictures : undefined,
    };

    const response = await createCompanyUseCase.invoke(company);
    switch (response.responseState) {
      case State.Success:
        showSnackbar("You need to login", "success");
        navigate(ROUTE_ENDPOINTS.LOGIN);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    imagesFiles,
    setImagesFile,
    doSignupProcess,
    companyInfo,
    setCompanyInfo,
    companyName,
    setCompanyName,
    email,
    setEmail,
    password,
    setPassword,
    signup,
    isLoading,
  };
}
