class SharedPreferences {

    static getString(key: string): string | null {
        return localStorage.getItem(key);
    }

    static setString(key: string, value: string): void {
        localStorage.setItem(key, value);
        window.dispatchEvent(new Event(key))
    }

    static setNumber(key: string, value: number): void {
        this.setString(key, value.toString());
    }

    static getNumber(key: string): number | null {
        const stringValue = this.getString(key);
        return stringValue ? parseFloat(stringValue) : null;
    }

    static setBoolean(key: string, value: boolean): void {
        this.setString(key, value.toString());
    }

    static getBoolean(key: string): boolean | null {
        const stringValue = this.getString(key);
        return stringValue ? stringValue === 'true' : null;
    }

    private static clearByKey(key: string) {
        localStorage.removeItem(key);
        window.dispatchEvent(new Event(key))

    }
    static clear() {
        for (let localStorageKey in localStorage) {
            this.clearByKey(localStorageKey)
        }
    }
}

export default SharedPreferences;
