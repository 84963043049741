import React, { useRef, useState, useEffect } from "react";
import AnimateHeight, { Height } from "react-animate-height";

interface autpHeightProps {
  children: React.ReactElement;
  duration?: number;
  props?: any;
}

const AutoHeight: React.FC<autpHeightProps> = ({
  children,
  duration = 500,
  ...props
}) => {
  const [height, setHeight] = useState<Height>("auto");
  const contentDiv = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = contentDiv.current as HTMLDivElement;

    const resizeObserver = new ResizeObserver(() => {
      setHeight(element.clientHeight);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <AnimateHeight
      {...props}
      height={height}
      contentClassName="auto-content"
      contentRef={contentDiv}
      disableDisplayNone
      duration={duration}
    >
      {children}
    </AnimateHeight>
  );
};

export default AutoHeight;
