import { CompanyRepository } from "../../../../data/repository/company/CompanyRepository";
import { IdModel, Message } from "../../../model/Auth";
import { Company, CreateCompanyRequestModel } from "../../../model/Company";
import { ResponseState, State } from "../../../model/ResponseState";

export interface CreateCompanyUseCase {
  invoke: (company: CreateCompanyRequestModel) => Promise<ResponseState<IdModel>>;
}

export class CreateCompanyUseCaseImpl implements CreateCompanyUseCase {
  private companyRepo: CompanyRepository;

  constructor(companyRepo: CompanyRepository) {
    this.companyRepo = companyRepo;
  }

  async invoke(company: CreateCompanyRequestModel): Promise<ResponseState<IdModel>> {
    try {
      let response = await this.companyRepo.create(company);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
