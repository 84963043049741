import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { GetAllEventsUseCase } from "../../../domain/usecase/event/getAll/GetAllUseCase";
import { State } from "../../../domain/model/ResponseState";
import { Event, getSampleEvents } from "../../../domain/model/Event";
import { GetCompanyDetailsUseCase } from "../../../domain/usecase/company/getOne/GetOneUseCase";
import SharedPreferences from "../../../core/utils/SharedPreferences";
import { STORAGE_KEYS } from "../../../data/constant/Constants";
import { Role } from "../../../domain/model/Auth";
import { useNavigate } from "react-router-dom";
import { DeleteEventUseCase } from "../../../domain/usecase/event/delete/DeleteUseCase";

export function EventsViewModel(
  getCompanyDetailsUseCase: GetCompanyDetailsUseCase,
  getAllEventsUseCase: GetAllEventsUseCase,
  deleteEventUseCase: DeleteEventUseCase
) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [createEventCheckingLoading, setCreateEventCheckingLoading] =
    useState(false);
  const [canCreateEvent, setCanCreateEvent] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const [events, setEvents] = useState<Array<Event> | undefined>([]);

  const [selectedEventId, setSelectedEventId] = useState<string | undefined>();
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [skip, setSkip] = useState(0);
  const limit = 10;

  useEffect(() => {
    getAllEvents();
    checkIfCanCreateEvent();
  }, []);

  function checkIfCanCreateEvent() {
    if (SharedPreferences.getString(STORAGE_KEYS.ROLE) == Role.SUPER_ADMIN) {
      setCanCreateEvent(true);
    } else {
      getCompanyDetails();
    }
  }

  async function deleteEvent() {
    if (!selectedEventId) return;
    setIsBackdropLoading(true);
    const response = await deleteEventUseCase.invoke(selectedEventId);
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        navigate(0);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsBackdropLoading(false);
  }

  async function getCompanyDetails() {
    setCreateEventCheckingLoading(true);
    const response = await getCompanyDetailsUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        const isVerified = response.data?.isVerified ?? false;
        setCanCreateEvent(isVerified);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setCreateEventCheckingLoading(false);
  }

  async function getAllEvents() {
    setIsLoading(true);
    const response = await getAllEventsUseCase.invoke(skip, limit);
    switch (response.responseState) {
      case State.Success:
        if (!response.data) return;
        if (response.data.length < limit) setShowLoadMoreButton(false);
        else {
          setShowLoadMoreButton(true);
        }
        setEvents(events?.concat(response.data));
        setSkip(skip + limit);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    openDialog,
    setOpenDialog,
    createEventCheckingLoading,
    canCreateEvent,
    isLoading,
    events,
    showLoadMoreButton,
    getAllEvents,
    deleteEvent,
    isBackdropLoading,
    setSelectedEventId,
  };
}
