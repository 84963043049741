export interface Auth {
  email: string;
  password: string;
}

export interface SignupRequestModel {
  email: string;
  password: string;
  role: Role;
}

export enum Role {
  SUPER_ADMIN = "super admin",
  COMPANY = "company",
  BASIC_USER = "basic",
}

export interface AccessToken {
  accessToken: string;
  role: string
}

export interface IdModel {
  id: string;
}

export interface Message {
  message: string;
}

export interface User {
  _id: string;
  email: string;
  createdAt: Date;
}
