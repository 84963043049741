import { Button, Stack } from "@mui/material";
import {
  COLOR_BLACK,
  COLOR_DARK_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { ReactElement } from "react";
import React from "react";
import { CustomTextView } from "../../component/CustomTextView";

export interface DrawerItem {
  isRoute: boolean;
  title: string;
  icon: ReactElement;
  onClick?: () => void;
  isSelected?: boolean;
  href?: string;
}

export function DrawerItemView(props: DrawerItem) {
  return (
    <Button
      key={props.title}
      sx={{
        background: props.isSelected ? COLOR_DARK_BLACK : COLOR_MAIN,
        paddingTop: 2.5,
        paddingBottom: 2.5,
        paddingLeft: 4,
        paddingRight: 4,
        marginLeft: props.isRoute ? (!props.isSelected ? 0 : 2) : 2,
        marginTop: 1.5,
        marginRight: props.isSelected ? 0 : 2,
        borderTopLeftRadius: props.isRoute ? (!props.isSelected ? 0 : 44) : 44,
        borderBottomLeftRadius: props.isRoute
          ? !props.isSelected
            ? 0
            : 44
          : 44,
        borderTopRightRadius: props.isSelected ? 0 : 44,
        borderBottomRightRadius: props.isSelected ? 0 : 44,
        border: props.isSelected ? `${COLOR_MAIN} solid 1px` : undefined,
        borderRightWidth: 0,
        "&:hover": {
          backgroundColor: COLOR_LIGHT_GRAY,
        },
        "& .MuiTouchRipple-child": {
          backgroundColor: COLOR_WHITE,
        },
      }}
      onClick={props.onClick}
      LinkComponent={"a"}
      href={props.href}
    >
      <Stack
        direction={"row"}
        spacing={1}
        justifyContent={props.isRoute ? "start" : "center"}
        flex={1}
      >
        {React.Children.map(props.icon, (child) => {
          return React.cloneElement(child, {
            sx: { color: !props.isSelected ? COLOR_BLACK : COLOR_MAIN },
          });
        })}
        <CustomTextView
          text={props.title}
          textColor={!props.isSelected ? COLOR_BLACK : COLOR_MAIN}
          props={{ textTransform: "none" }}
          fontWeight={props.isSelected ? "bold" : "normal"}
        />
      </Stack>
    </Button>
  );
}
