import {
  Auth,
  AccessToken,
  Message,
  IdModel,
} from "../../../domain/model/Auth";
import {
  Company,
  CreateCompanyRequestModel,
  UpdateCompanyRequestModel,
} from "../../../domain/model/Company";
import { Event } from "../../../domain/model/Event";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { CompanyRepository } from "./CompanyRepository";

export class CompanyRepositoryImpl implements CompanyRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }

  create(company: CreateCompanyRequestModel): Promise<Response<IdModel>> {
    return this.apiService.createCompany(company);
  }
  getAll(skip?: number, limit?: number): Promise<Response<Array<Company>>> {
    return this.apiService.getCompanies(skip, limit);
  }
  getOne(): Promise<Response<Company>> {
    return this.apiService.getCompanyDetails();
  }
  update(
    id: string,
    company: UpdateCompanyRequestModel
  ): Promise<Response<Message>> {
    return this.apiService.updateCompany(id, company);
  }
}
