import SharedPreferences from "../../core/utils/SharedPreferences";
import { AxiosBuilder } from "../../data/api/AxiosBuilder";
import { BASE_URL } from "../../data/constant/ApiConstants";
import { STORAGE_KEYS } from "../../data/constant/Constants";

export function provideApiBuilder() {
  return new AxiosBuilder()
    .baseUrl(BASE_URL)
    .timeout(2 * 60 * 1000)
    .headers({
      Authorization: `Bearer ${SharedPreferences.getString(
        STORAGE_KEYS.TOKEN
      )}`,
    })
    .responseType("json")
    .build();
}
