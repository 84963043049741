import { Box } from "@mui/material";
import { randomUUID } from "crypto";
import { ReactElement } from "react";

interface filePickerInterface {
  handleFilePicked: (file: File) => void;
  layout: ReactElement;
  acceptType: string;
}

export function FilePicker(props: filePickerInterface) {
  function handleChange(e: any) {
    props.handleFilePicked(e.target.files[0]);
  }

  const randomId = Math.random().toString();

  return (
    <Box>
      <label htmlFor={randomId}>{props.layout}</label>
      <input
        id={randomId}
        type="file"
        onChange={handleChange}
        accept={props.acceptType}
        style={{ display: "none" }}
      />
    </Box>
  );
}
