import { Box, Typography } from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import {
  STRINGS_404_NOT_FOUND,
  STRINGS_NOTHING_TO_SHOW,
} from "../../data/strings/Strings";
import {
  Error,
  ErrorTwoTone,
  HourglassEmpty,
  HourglassEmptyRounded,
} from "@mui/icons-material";
import { COLOR_LESS_BLACK, COLOR_MAIN } from "../../data/colors/Colors";
import { CustomTextView } from "./CustomTextView";

interface p {
  minHeight?: string | number;
}
export function EmptyView(p: p) {
  return (
    <Box
      sx={{
        minHeight: p.minHeight ?? "70vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <HourglassEmptyRounded fontSize="large" sx={{ color: COLOR_MAIN }} />
      <CustomTextView
        text="Nothing to show"
        variant="h5"
        fontWeight="bold"
        textColor={COLOR_MAIN}
      />
    </Box>
  );
}
