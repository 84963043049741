import { useEffect, useState } from "react";
import { Company } from "../../../domain/model/Company";
import { GetAllCompaniesUseCase } from "../../../domain/usecase/company/getAll/GetAllUseCase";
import { useSnackbar } from "notistack";
import { State } from "../../../domain/model/ResponseState";
import { UpdateCompanyUseCase } from "../../../domain/usecase/company/update/UpdateUseCase";

export function CompaniesViewModel(
  getAllCompaniesUseCase: GetAllCompaniesUseCase,
  updateCompanyUseCase: UpdateCompanyUseCase
) {
  const [companies, setCompanies] = useState<Array<Company> | undefined>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [skip, setSkip] = useState(0);
  const limit = 20;

  useEffect(() => {
    getCompanies();
  }, []);

  async function getCompanies() {
    setIsLoading(true);
    const response = await getAllCompaniesUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        if (!response.data) return;
        if (response.data.length < limit) setShowLoadMoreButton(false);
        else {
          setShowLoadMoreButton(true);
        }
        setCompanies(companies?.concat(response.data));
        setSkip(skip + limit);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  async function verifyUnVerifyCompany(id: string, isVerified: boolean) {
    setIsBackdropLoading(true);
    const response = await updateCompanyUseCase.invoke(id, {
      isVerified: !isVerified,
    });
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        const editedCompanies = companies;
        editedCompanies?.map((c) => {
          if (c._id == id) c.isVerified = !isVerified;
        });
        setCompanies(editedCompanies);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsBackdropLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    companies,
    isLoading,
    showLoadMoreButton,
    getCompanies,
    isBackdropLoading,
    verifyUnVerifyCompany,
  };
}
