import { useEffect, useState } from "react";
import { Company } from "../../../domain/model/Company";
import { GetCompanyDetailsUseCase } from "../../../domain/usecase/company/getOne/GetOneUseCase";
import { useSnackbar } from "notistack";
import { State } from "../../../domain/model/ResponseState";
import { UpdateCompanyUseCase } from "../../../domain/usecase/company/update/UpdateUseCase";
import { uploadFile } from "../../../core/utils/Utils";
import { provideUploadFileUseCase } from "../../di/UseCasesModule";

export function ProfileViewModel(
  getCompanyDetailsUseCase: GetCompanyDetailsUseCase,
  updateCompanyUseCase: UpdateCompanyUseCase
) {
  const [company, setCompany] = useState<Company | undefined>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isBackdropLoading, setIsBackdropLoading] = useState(false);
  const [editable, setEditabled] = useState(false);

  const [imagesFiles, setImagesFile] = useState<Array<File>>([]);

  useEffect(() => {
    getCompanyDetails();
  }, []);

  async function getCompanyDetails() {
    setIsLoading(true);
    const response = await getCompanyDetailsUseCase.invoke();
    switch (response.responseState) {
      case State.Success:
        setCompany(response.data);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsLoading(false);
  }

  async function updateCompany() {
    if (!company) return;
    if (!company._id) return;
    if (!company.name) {
      showSnackbar("Company name cannot be empty");
      return;
    }
    if (!company.info) {
      showSnackbar("Company info cannot be empty");
      return;
    }
    setIsBackdropLoading(true);

    const presentationPicturesFileIds: Array<string> =
      company.presentationPictures
        ? company.presentationPictures.flatMap((f) => f._id!)
        : [];

    if (imagesFiles.length > 0) {
      await Promise.all(
        imagesFiles.map(async (file) => {
          const uploadedFile = await uploadFile(
            provideUploadFileUseCase(),
            file
          );
          if (uploadedFile?._id)
            presentationPicturesFileIds?.push(uploadedFile._id);
        })
      );
    }

    const response = await updateCompanyUseCase.invoke(company._id, {
      name: company.name,
      info: company.info,
      presentationPictures: presentationPicturesFileIds,
    });
    switch (response.responseState) {
      case State.Success:
        showSnackbar(response.data?.message, "success");
        setEditabled(false);
        break;
      case State.Fail:
      case State.Error:
        showSnackbar(response.error?.message);
        break;
    }
    setIsBackdropLoading(false);
  }

  function showSnackbar(
    message: string | undefined,
    variant: "error" | "success" = "error"
  ) {
    if (message) enqueueSnackbar(message, { variant: variant });
  }

  return {
    company,
    editable,
    setEditabled,
    setCompany,
    isLoading,
    isBackdropLoading,
    updateCompany,
    setImagesFile,
    imagesFiles,
  };
}
