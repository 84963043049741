import { SxProps, Typography } from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import { Theme } from "@emotion/react";
import { COLOR_BLACK, COLOR_LESS_BLACK, COLOR_WHITE } from "../../data/colors/Colors";

interface props {
  text: string | undefined;
  fontFamily?: string;
  fontWeight?: string;
  fontSize?: number;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  textColor?: string;
  textAlign?: "start" | "center" | "end";
  props?: SxProps<Theme>;
}

export const CustomTextView: React.FC<props> = ({
  text,
  fontFamily = MAIN_FONT_FAMILY,
  fontWeight = "normal",
  fontSize,
  variant,
  textColor = COLOR_BLACK,
  textAlign = "start",
  props,
}: props) => {
  return (
    <Typography
      fontSize={fontSize}
      variant={variant}
      textAlign={textAlign}
      color={textColor}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
      sx={props}
    >
      {text}
    </Typography>
  );
};
