import { AuthRepository } from "../../../../data/repository/auth/AuthRepository";
import { AccessToken, Auth, IdModel, SignupRequestModel } from "../../../model/Auth";
import { ResponseState, State } from "../../../model/ResponseState";

export interface SignupUseCase {
  invoke: (model: SignupRequestModel) => Promise<ResponseState<IdModel>>;
}

export class SignupUseCaseImpl implements SignupUseCase {
  private authRepository: AuthRepository;

  constructor(authRepository: AuthRepository) {
    this.authRepository = authRepository;
  }

  async invoke(model: SignupRequestModel): Promise<ResponseState<IdModel>> {
    try {
      let response = await this.authRepository.signup(model);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
