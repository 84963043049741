import { Backdrop, CircularProgress, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import useWindowDimensions from "../../../../core/utils/UseWindowDimensions";
import {
  COLOR_BLACK,
  COLOR_LESS_BLACK,
  COLOR_WHITE,
} from "../../../../data/colors/Colors";
import { SIGNUP_PAGE_BACKGROUND_IMAGE } from "../../../../data/constant/Constants";
import { CustomImageView } from "../../../component/CustomImageView";
import { SignupForm } from "./SignupForm";
import { SignupViewModel } from "./SignupViewModel";
import {
  provideCreateCompanyUseCase,
  provideSignupUseCase,
  provideUploadFileUseCase,
} from "../../../di/UseCasesModule";

export function SignupView() {
  const { width } = useWindowDimensions();

  const viewModel = SignupViewModel(
    provideSignupUseCase(),
    provideCreateCompanyUseCase(),
    provideUploadFileUseCase()
  );
  return (
    <Box>
      <Grid container>
        <Grid
          xs={12}
          sm={12}
          md={6}
          xl={5}
          sx={{ background: COLOR_LESS_BLACK }}
        >
          <SignupForm
            imagesFiles={viewModel.imagesFiles}
            setImageFile={(index, file) => {
              const newList = [...viewModel.imagesFiles];
              newList[index] = file;
              viewModel.setImagesFile(newList);
            }}
            email={viewModel.email}
            setEmail={viewModel.setEmail}
            password={viewModel.password}
            setPassword={viewModel.setPassword}
            companyInfo={viewModel.companyInfo}
            setCompanyInfo={viewModel.setCompanyInfo}
            companyName={viewModel.companyName}
            setCompanyName={viewModel.setCompanyName}
            handleSubmit={viewModel.doSignupProcess}
          />
        </Grid>
        <Grid xs={0} sm={0} md={6} xl={7}>
          <Box
            sx={{
              height: "100%",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              background: COLOR_LESS_BLACK,
            }}
          >
            <CustomImageView
              width={width > 900 ? "100%" : "0%"}
              height={width > 900 ? "100%" : "0%"}
              src={SIGNUP_PAGE_BACKGROUND_IMAGE}
              props={{
                borderTopLeftRadius: 40,
                borderBottomLeftRadius: 40,
                background: COLOR_BLACK,
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: COLOR_WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewModel.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
