import { SnackbarOrigin } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";

const MAX_SNACK = 5;
const AUTO_HIDE_DURATION = 3000;
const POSITION: SnackbarOrigin = {
  vertical: "top",
  horizontal: "right",
};

export default function NotistackWrapper({ children }: any) {
  return (
    <SnackbarProvider
      maxSnack={MAX_SNACK}
      autoHideDuration={AUTO_HIDE_DURATION}
      anchorOrigin={POSITION}
      style={{ fontFamily: MAIN_FONT_FAMILY }}
    >
      {children}
    </SnackbarProvider>
  );
}
