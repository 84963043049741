import {
  Box,
  Button,
  Divider,
  Stack,
  Step,
  StepButton,
  StepConnector,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  COLOR_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../../data/colors/Colors";
import { CustomTextView } from "../../../component/CustomTextView";
import { CustomTextField } from "../../../component/CustomTextField";
import useWindowDimensions from "../../../../core/utils/UseWindowDimensions";
import {
  EmailRounded,
  ImageOutlined,
  InfoRounded,
  LockRounded,
} from "@mui/icons-material";
import {
  STRINGS_COMPANY_INFO,
  STRINGS_COMPANY_INFO_AND_PRESENTATION_IMAGES,
  STRINGS_COMPANY_NAME,
  STRINGS_COMPANY_NAME_REQUIRED,
  STRINGS_EMAIL,
  STRINGS_EMAIL_NOT_VALID,
  STRINGS_EMAIL_REQUIRED,
  STRINGS_NEXT,
  STRINGS_PASSWORD_REQUIRED,
  STRINGS_REGISTER_WITH_COMPANY_NAME_EMAIL,
  STRINGS_SIGNUP,
} from "../../../../data/strings/Strings";
import AutoHeight from "../../../component/AutoHeightView";
import { FilePicker } from "../../../component/FilePicker";
import { CustomImageView } from "../../../component/CustomImageView";
import { Validation } from "../../../../domain/validation/Validation";

interface props {
  imagesFiles: Array<File | null>;
  setImageFile: (index: number, file: File) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  companyInfo: string;
  setCompanyInfo: (info: string) => void;
  companyName: string;
  setCompanyName: (name: string) => void;
  handleSubmit: () => void;
}

const steps = [
  {
    label: STRINGS_COMPANY_INFO_AND_PRESENTATION_IMAGES,
  },
  {
    label: STRINGS_REGISTER_WITH_COMPANY_NAME_EMAIL,
  },
];

export function SignupForm(p: props) {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const { width } = useWindowDimensions();

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateInputs();
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      handleNext();
      p.handleSubmit();
    }
  };

  const validateInputs = () => {
    const errors: { [key: string]: string } = {};
    const emailValid = Validation.validateEmail(p.email);
    if (emailValid !== true) {
      errors.email = STRINGS_EMAIL_NOT_VALID;
    }
    if (p.email.length === 0) {
      errors.email = STRINGS_EMAIL_REQUIRED;
    }
    if (p.password.length === 0) {
      errors.password = STRINGS_PASSWORD_REQUIRED;
    }
    if (p.companyName.length === 0) {
      errors.companyName = STRINGS_COMPANY_NAME_REQUIRED;
    }
    return errors;
  };

  return (
    <Stack
      padding={width < 800 ? 6 : 10}
      width={"100%"}
      spacing={10}
      alignItems={"center"}
      justifyContent={"center"}
      direction={"column"}
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <CustomTextView
        text={STRINGS_SIGNUP}
        variant="h3"
        textColor={COLOR_WHITE}
        fontWeight="bold"
      />
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step
            key={step.label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: COLOR_MAIN,
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: COLOR_WHITE,
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: COLOR_MAIN,
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: COLOR_WHITE,
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: COLOR_WHITE,
              },
            }}
          >
            <StepButton onClick={() => setActiveStep(index)}>
              <CustomTextView
                text={step.label}
                textColor={
                  index === activeStep
                    ? COLOR_WHITE
                    : index < activeStep
                    ? COLOR_MAIN
                    : COLOR_LIGHT_GRAY
                }
                fontWeight={index === activeStep ? "bold" : "300"}
                fontSize={14}
              />
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Stack spacing={10} width={"100%"}>
        <AutoHeight duration={150}>
          {activeStep == 0 ? (
            <Stack spacing={2}>
              <CustomTextField
                placeholder={STRINGS_COMPANY_INFO}
                isFullWidth
                startIcon={<InfoRounded sx={{ color: COLOR_MAIN }} />}
                textColor={COLOR_WHITE}
                onHoverBorderColor={COLOR_LIGHT_GRAY}
                onFocusBorderColor={COLOR_LIGHT_GRAY}
                borderColor={COLOR_MAIN}
                height={140}
                multiLine
                borderRadius={6}
                maxRows={5}
                backgroundColor={"transparent"}
                onTextChange={p.setCompanyInfo}
                value={p.companyInfo}
              />
              <Stack
                direction={"row"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"space-between"}
                spacing={2}
              >
                {p.imagesFiles.map((file: File | null, index) => {
                  return (
                    <Button
                      sx={{
                        width: "100%",
                        height: 120,
                        padding: 0,
                        borderRadius: 6,
                        borderColor: COLOR_MAIN,
                        "&:hover": {
                          borderColor: COLOR_LIGHT_GRAY,
                        },
                      }}
                      variant="outlined"
                    >
                      <FilePicker
                        acceptType="image/*"
                        handleFilePicked={(file) => {
                          p.setImageFile(index, file);
                        }}
                        layout={
                          <>
                            {file ? (
                              <Box height={118}>
                                <CustomImageView
                                  width={"100%"}
                                  height={"100%"}
                                  src={URL.createObjectURL(file)}
                                  props={{ borderRadius: 6 }}
                                />
                              </Box>
                            ) : (
                              <Stack
                                alignItems={"center"}
                                padding={4}
                                spacing={1}
                              >
                                <ImageOutlined
                                  fontSize={"large"}
                                  sx={{ color: COLOR_MAIN }}
                                />
                                <CustomTextView
                                  text="Upload an Image"
                                  textColor={COLOR_WHITE}
                                  fontSize={12}
                                  textAlign="center"
                                  props={{ textTransform: "none" }}
                                />
                              </Stack>
                            )}
                          </>
                        }
                      />
                    </Button>
                  );
                })}
              </Stack>
              <Button
                onClick={handleNext}
                sx={{
                  borderRadius: 12,
                  background: COLOR_MAIN,
                  "&:hover": { background: COLOR_LIGHT_GRAY },
                }}
                variant="contained"
              >
                <CustomTextView
                  text={STRINGS_NEXT}
                  variant="h6"
                  props={{ p: 1, textTransform: "none" }}
                  fontWeight="bold"
                  textColor={COLOR_WHITE}
                />
              </Button>
            </Stack>
          ) : (
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <Stack spacing={2}>
                <CustomTextField
                  placeholder={STRINGS_COMPANY_NAME}
                  isFullWidth
                  onTextChange={p.setCompanyName}
                  value={p.companyName}
                  startIcon={<InfoRounded sx={{ color: COLOR_MAIN }} />}
                  textColor={COLOR_WHITE}
                  onHoverBorderColor={COLOR_LIGHT_GRAY}
                  onFocusBorderColor={COLOR_LIGHT_GRAY}
                  borderColor={COLOR_MAIN}
                  backgroundColor={"transparent"}
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
                <CustomTextField
                  type="email"
                  placeholder={STRINGS_EMAIL}
                  isFullWidth
                  onTextChange={p.setEmail}
                  value={p.email}
                  startIcon={<EmailRounded sx={{ color: COLOR_MAIN }} />}
                  textColor={COLOR_WHITE}
                  onHoverBorderColor={COLOR_LIGHT_GRAY}
                  onFocusBorderColor={COLOR_LIGHT_GRAY}
                  borderColor={COLOR_MAIN}
                  backgroundColor={"transparent"}
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <CustomTextField
                  placeholder="Password"
                  isFullWidth
                  type="password"
                  onTextChange={p.setPassword}
                  value={p.password}
                  startIcon={<LockRounded sx={{ color: COLOR_MAIN }} />}
                  textColor={COLOR_WHITE}
                  onHoverBorderColor={COLOR_LIGHT_GRAY}
                  onFocusBorderColor={COLOR_LIGHT_GRAY}
                  borderColor={COLOR_MAIN}
                  backgroundColor={"transparent"}
                  error={!!errors.password}
                  helperText={errors.password}
                />
                <Button
                  type="submit"
                  sx={{
                    borderRadius: 12,
                    background: COLOR_MAIN,
                    "&:hover": { background: COLOR_LIGHT_GRAY },
                  }}
                  variant="contained"
                >
                  <CustomTextView
                    text="Signup"
                    variant="h6"
                    props={{ p: 1, textTransform: "none" }}
                    fontWeight="bold"
                    textColor={COLOR_WHITE}
                  />
                </Button>
              </Stack>
            </Box>
          )}
        </AutoHeight>
      </Stack>
    </Stack>
  );
}
