import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import {
  COLOR_BLACK,
  COLOR_DARK_BLACK,
  COLOR_MAIN,
} from "../../../data/colors/Colors";
import { CustomTextView } from "../../component/CustomTextView";
import {
  STRINGS_ACTIONS,
  STRINGS_COMPANIES,
  STRINGS_EVENTS,
  STRINGS_FIRTEK_AUDIO_SYNC,
  STRINGS_LOGOUT,
  STRINGS_PAGES,
  STRINGS_PROFILE,
} from "../../../data/strings/Strings";
import {
  EventRounded,
  LogoutRounded,
  ManRounded,
  PersonRounded,
  PixRounded,
} from "@mui/icons-material";
import { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { DrawerItem, DrawerItemView } from "./DrawerItemView";
import SharedPreferences from "../../../core/utils/SharedPreferences";
import { Error404View } from "../../component/404View";
import MenuIcon from "@mui/icons-material/Menu";
import { ROUTE_ENDPOINTS } from "../../../data/constant/RouteConstants";
import { EventsView } from "../events/EventsView";
import { EventDetailsView } from "../events/details/EventDetailsView";
import { ProfileView } from "../profile/ProfileView";
import { CompaniesView } from "../companies/CompaniesView";
import { STORAGE_KEYS } from "../../../data/constant/Constants";
import { Role } from "../../../domain/model/Auth";

const drawerWidth = 300;

export function HomeView() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const location = useLocation();

  const drawerRouterItems: Array<DrawerItem> =
    SharedPreferences.getString(STORAGE_KEYS.ROLE) == Role.SUPER_ADMIN
      ? [
          {
            isRoute: true,
            title: STRINGS_EVENTS,
            icon: <EventRounded />,
            isSelected: location.pathname === ROUTE_ENDPOINTS.HOME_EVENTS,
            href: ROUTE_ENDPOINTS.HOME_EVENTS,
          },
          {
            isRoute: true,
            title: STRINGS_COMPANIES,
            icon: <PixRounded />,
            isSelected: location.pathname === ROUTE_ENDPOINTS.HOME_COMPANIES,
            href: ROUTE_ENDPOINTS.HOME_COMPANIES,
          },
        ]
      : [
          {
            isRoute: true,
            title: STRINGS_EVENTS,
            icon: <EventRounded />,
            isSelected: location.pathname === ROUTE_ENDPOINTS.HOME_EVENTS,
            href: ROUTE_ENDPOINTS.HOME_EVENTS,
          },
          {
            isRoute: true,
            title: STRINGS_PROFILE,
            icon: <PersonRounded />,
            isSelected: location.pathname === ROUTE_ENDPOINTS.HOME_PROFILE,
            href: ROUTE_ENDPOINTS.HOME_PROFILE,
          },
        ];

  const drawerButtonsItems: Array<DrawerItem> = [
    {
      isRoute: false,
      title: STRINGS_LOGOUT,
      icon: <LogoutRounded />,
      onClick: () => {
        SharedPreferences.clear();
      },
    },
  ];

  const drawer = (
    <div style={{ height: "100%", background: COLOR_BLACK }}>
      <Toolbar />
      <Stack>
        <CustomTextView
          text={STRINGS_PAGES}
          props={{
            paddingTop: 2,
            paddingLeft: 4,
            paddingRight: 4,
          }}
          fontSize={16}
          textColor={COLOR_MAIN}
        />
        {drawerRouterItems.map((item) => (
          <DrawerItemView
            icon={item.icon}
            key={item.title}
            isRoute={item.isRoute}
            title={item.title}
            isSelected={item.isSelected}
            href={item.href}
          />
        ))}
        <Box sx={{ marginTop: 2 }} />
        <Divider sx={{ marginTop: 2, background: COLOR_MAIN, height: 2 }} />
        <CustomTextView
          text={STRINGS_ACTIONS}
          props={{
            paddingTop: 2,
            paddingLeft: 4,
            paddingRight: 4,
          }}
          fontSize={16}
          textColor={COLOR_MAIN}
        />
        {drawerButtonsItems.map((item) => (
          <DrawerItemView
            key={item.title}
            icon={item.icon}
            isRoute={item.isRoute}
            onClick={item.onClick}
            title={item.title}
          />
        ))}
      </Stack>
    </div>
  );
  return (
    <Box
      sx={{ display: "flex", background: COLOR_DARK_BLACK, minHeight: "100vh" }}
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: COLOR_BLACK,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 0, mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon sx={{ color: COLOR_MAIN }} />
          </IconButton>
          <CustomTextView
            text={STRINGS_FIRTEK_AUDIO_SYNC}
            variant="h5"
            fontWeight="bold"
            textColor={COLOR_MAIN}
          />
        </Toolbar>
        <Divider sx={{ background: COLOR_MAIN, height: 2 }} />
      </AppBar>
      <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderColor: COLOR_MAIN,
              borderWidth: 1,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Routes>
          <Route path={ROUTE_ENDPOINTS.EVENTS} element={<EventsView />} />
          <Route
            path={`${ROUTE_ENDPOINTS.EVENTS}/:id`}
            element={<EventDetailsView />}
          />
          <Route path={ROUTE_ENDPOINTS.PROFILE} element={<ProfileView />} />
          <Route path={ROUTE_ENDPOINTS.COMPANIES} element={<CompaniesView />} />
          <Route path="*" element={<Error404View />} />
        </Routes>
      </Box>
    </Box>
  );
}
