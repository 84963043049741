export const ROUTE_ENDPOINTS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  HOME: "/home/*",
  HOME_PROFILE: "/home/profile",
  HOME_EVENTS: "/home/events",
  HOME_COMPANIES: "/home/companies",
  EVENTS: "/events",
  PROFILE: "/profile",
  COMPANIES: "/companies",
};

export const ROUTE_NEW = "new";