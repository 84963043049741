import { CompanyRepository } from "../../../../data/repository/company/CompanyRepository";
import { EventRepository } from "../../../../data/repository/event/EventRepository";
import { Company } from "../../../model/Company";
import { Event } from "../../../model/Event";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetAllCompaniesUseCase {
  invoke: (
    skip?: number,
    limit?: number
  ) => Promise<ResponseState<Array<Company>>>;
}

export class GetAllCompaniesUseCaseImpl implements GetAllCompaniesUseCase {
  private companyRepo: CompanyRepository;

  constructor(companyRepo: CompanyRepository) {
    this.companyRepo = companyRepo;
  }

  async invoke(
    skip?: number,
    limit?: number
  ): Promise<ResponseState<Array<Company>>> {
    try {
      let response = await this.companyRepo.getAll(skip, limit);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
