import { Box } from "@mui/material";

interface ImageViewDetails {
  src?: string | null;
  width: string | number;
  height: string | number;
  scale?: string;
  props?: any;
}

export const CustomImageView: React.FC<ImageViewDetails> = ({
  src,
  width,
  height,
  scale = "cover",
  props,
}: ImageViewDetails) => {
  return src ? (
    <Box
      component="img"
      src={src ?? ""}
      sx={[
        {
          width: width,
          height: height,
          objectFit: scale,
        },
        props,
      ]}
    />
  ) : (
    <></>
  );
};
