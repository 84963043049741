// auth
export const STRINGS_LOGIN = "Login"
export const STRINGS_LOGOUT = "Logout"
export const STRINGS_SIGNUP= "Signup"
export const STRINGS_EMAIL = "Email"
export const STRINGS_PASSWORD = "Password"
export const STRINGS_FIRTEK_AUDIO_SYNC = "Firetek Audio Sync"
export const STRINGS_NEXT = "Next"
export const STRINGS_COMPANY_NAME = "Company Name"
export const STRINGS_COMPANY_INFO = "Company Info"
export const STRINGS_COMPANY_INFO_AND_PRESENTATION_IMAGES = "Company Info and Presentation Pictures (optional)"
export const STRINGS_REGISTER_WITH_COMPANY_NAME_EMAIL = "Register with Company Name and Email"
export const STRINGS_CANCEl = "Cancel";
export const STRINGS_OK = "Ok";

// home
export const STRINGS_EVENTS = "Events"
export const STRINGS_NAME = "Name" 
export const STRINGS_AUDIO_URL = "Audio URL" 
export const STRINGS_NOTHING_TO_SHOW = "Nothing to show";
export const STRINGS_ACTIONS = "Actions"
export const STRINGS_PROFILE = "Profile"
export const STRINGS_COMPANIES = "Companies"
export const STRINGS_PAGES = "Pages"

// errors
export const STRINGS_404_NOT_FOUND = "404 Not Found";
export const STRINGS_EMAIL_NOT_VALID = "Email not valid";
export const STRINGS_EMAIL_REQUIRED = "Email is required";
export const STRINGS_PASSWORD_REQUIRED = "Password is required";
export const STRINGS_COMPANY_NAME_REQUIRED = "Company Name is required";