import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { CustomTextField } from "../../component/CustomTextField";
import { CustomTextView } from "../../component/CustomTextView";
import {
  COLOR_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import { ProfileViewModel } from "./ProfileViewModel";
import { CustomCardView } from "../../component/CustomCardView";
import {
  Check,
  CheckCircleRounded,
  CheckRounded,
  CloseRounded,
  Delete,
  Edit,
  ImageOutlined,
  X,
} from "@mui/icons-material";
import AutoHeight from "../../component/AutoHeightView";
import { FilePicker } from "../../component/FilePicker";
import { CustomImageView } from "../../component/CustomImageView";
import {
  provideGetCompanyDetailsUseCase,
  provideUpdateCompanyUseCase,
} from "../../di/UseCasesModule";
import { LoadingView } from "../../component/LoadingView";

export function ProfileView() {
  const viewModel = ProfileViewModel(
    provideGetCompanyDetailsUseCase(),
    provideUpdateCompanyUseCase()
  );

  return (
    <Stack p={3}>
      <Backdrop
        sx={{ color: COLOR_WHITE, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={viewModel.isBackdropLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
          <CustomTextView
            text="Profile"
            variant="h4"
            textColor={COLOR_MAIN}
            fontWeight="bold"
          />
          {viewModel.company && (
            <>
              {viewModel.company.isVerified ? (
                <Tooltip title="Verified">
                  <CheckCircleRounded
                    sx={{ color: "green" }}
                    fontSize="large"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="NOT Verified">
                  <CloseRounded sx={{ color: "red" }} fontSize="large" />
                </Tooltip>
              )}
            </>
          )}
        </Stack>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          {viewModel.company && (
            <IconButton
              onClick={() => viewModel.setEditabled(!viewModel.editable)}
              sx={{ background: COLOR_MAIN }}
            >
              <Edit sx={{ color: COLOR_WHITE }} fontSize="large" />
            </IconButton>
          )}
          {viewModel.company && viewModel.editable && (
            <IconButton
              onClick={() => viewModel.updateCompany()}
              sx={{ background: COLOR_MAIN }}
            >
              <CheckRounded sx={{ color: COLOR_WHITE }} fontSize="large" />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {viewModel.isLoading && <LoadingView />}
      {viewModel.company && (
        <Stack marginTop={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TitledView
                title="Email"
                layout={
                  <>
                    <CustomTextView
                      text={viewModel.company.user?.email}
                      fontSize={24}
                      fontWeight="bold"
                    />
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <TitledView
                title="Name"
                layout={
                  <>
                    {viewModel.editable ? (
                      <CustomTextField
                        placeholder="Name"
                        value={viewModel.company.name}
                        onTextChange={(txt) => {
                          if (!viewModel.company) return;
                          viewModel.setCompany({
                            ...viewModel.company,
                            name: txt,
                          });
                        }}
                        backgroundColor={"transparent"}
                        variant="standard"
                        fontSize={24}
                        isFullWidth={false}
                        textColor={COLOR_BLACK}
                        multiLine
                        maxRows={6}
                      />
                    ) : (
                      <CustomTextView
                        text={viewModel.company.name}
                        fontSize={24}
                      />
                    )}
                  </>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TitledView
                title="Info"
                layout={
                  <>
                    {viewModel.editable ? (
                      <CustomTextField
                        placeholder="Info"
                        value={viewModel.company.info}
                        onTextChange={(txt) => {
                          if (!viewModel.company) return;
                          viewModel.setCompany({
                            ...viewModel.company,
                            info: txt,
                          });
                        }}
                        backgroundColor={"transparent"}
                        variant="standard"
                        fontSize={24}
                        isFullWidth={false}
                        textColor={COLOR_BLACK}
                        multiLine
                        maxRows={6}
                      />
                    ) : (
                      <CustomTextView
                        text={viewModel.company.info}
                        fontSize={24}
                      />
                    )}
                  </>
                }
              />
            </Grid>
            )
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextView
                text="Presentation Images"
                variant="h5"
                textColor={COLOR_WHITE}
                fontWeight="bold"
                textAlign="center"
                props={{ marginBottom: 2 }}
              />
              <Grid
                container
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {viewModel.company.presentationPictures?.map((file, index) => {
                  return (
                    <Grid item p={1} key={index}>
                      <Box height={200} width={300}>
                        <CustomImageView
                          width={"100%"}
                          height={"100%"}
                          src={file.downloadUrl}
                          props={{ borderRadius: 6 }}
                        />
                        {viewModel.editable && (
                          <Box
                            sx={{
                              zIndex: 1,
                              marginTop: -26,
                              marginLeft: 33,
                              position: "absolute",
                            }}
                          >
                            <IconButton
                              sx={{
                                background: "red",
                              }}
                              onClick={() => {
                                if (viewModel.company?.presentationPictures)
                                  viewModel.setCompany({
                                    ...viewModel.company,
                                    presentationPictures:
                                      viewModel.company.presentationPictures.filter(
                                        (_, i) => i != index
                                      ),
                                  });
                              }}
                            >
                              <Delete
                                sx={{
                                  color: COLOR_WHITE,
                                }}
                              />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                })}

                {viewModel.imagesFiles.map((file, index) => {
                  return (
                    <Grid item p={1} key={index}>
                      <Box height={200} width={300}>
                        <CustomImageView
                          width={"100%"}
                          height={"100%"}
                          src={URL.createObjectURL(file)}
                          props={{ borderRadius: 6 }}
                        />
                        {viewModel.editable && (
                          <Box
                            sx={{
                              zIndex: 1,
                              marginTop: -26,
                              marginLeft: 33,
                              position: "absolute",
                            }}
                          >
                            <IconButton
                              sx={{
                                background: "red",
                              }}
                              onClick={() => {
                                viewModel.setImagesFile(
                                  viewModel.imagesFiles.filter(
                                    (_, i) => i != index
                                  )
                                );
                              }}
                            >
                              <Delete
                                sx={{
                                  color: COLOR_WHITE,
                                }}
                              />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
                {viewModel.editable && (
                  <Grid item p={1}>
                    <Button
                      sx={{
                        width: 300,
                        height: 200,
                        padding: 0,
                        borderRadius: 6,
                        borderColor: COLOR_MAIN,
                        "&:hover": {
                          borderColor: COLOR_LIGHT_GRAY,
                        },
                      }}
                      variant="outlined"
                    >
                      <FilePicker
                        acceptType="image/*"
                        handleFilePicked={(file) => {
                          viewModel.setImagesFile([
                            ...viewModel.imagesFiles,
                            file,
                          ]);
                        }}
                        layout={
                          <Stack alignItems={"center"} padding={4} spacing={1}>
                            <ImageOutlined
                              fontSize={"large"}
                              sx={{ color: COLOR_MAIN }}
                            />
                            <CustomTextView
                              text="Upload an Image"
                              textColor={COLOR_WHITE}
                              fontSize={14}
                              textAlign="center"
                              props={{ textTransform: "none" }}
                            />
                          </Stack>
                        }
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            )
          </Grid>
        </Stack>
      )}
    </Stack>
  );
}

interface p {
  title: string;
  layout: React.ReactElement;
}
function TitledView(p: p) {
  return (
      <Box sx={{ wordWrap: "break-word" }}>
        <CustomCardView
          children={
            <Stack spacing={1}>
              <CustomTextView
                text={p.title}
                variant="h5"
                textColor={COLOR_LIGHT_GRAY}
              />
              {p.layout}
            </Stack>
          }
        />
      </Box>
  );
}
