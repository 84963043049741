import { Box, Typography } from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import { STRINGS_404_NOT_FOUND } from "../../data/strings/Strings";
import { Error, ErrorTwoTone } from "@mui/icons-material";
import { CustomTextView } from "./CustomTextView";
import { COLOR_BLACK, COLOR_WHITE } from "../../data/colors/Colors";

export function Error404View() {
  return (
    <Box
      sx={{
        minHeight: "90vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ErrorTwoTone fontSize="large" sx={{ color: "red" }} />
      <CustomTextView
        text={STRINGS_404_NOT_FOUND}
        fontWeight="bold"
        variant="h5"
        textColor={COLOR_WHITE}
      />
    </Box>
  );
}
