import { Auth, AccessToken, SignupRequestModel, IdModel } from "../../../domain/model/Auth";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { AuthRepository } from "./AuthRepository";

export class AuthRepositoryImpl implements AuthRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }

  login(auth: Auth): Promise<Response<AccessToken>> {
    return this.apiService.login(auth);
  }
  signup(model: SignupRequestModel): Promise<Response<IdModel>> {
    return this.apiService.signup(model);
  }

}
