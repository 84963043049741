import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Validation } from "../../../../domain/validation/Validation";
import { Card, Stack } from "@mui/material";
import {
  STRINGS_EMAIL,
  STRINGS_EMAIL_NOT_VALID,
  STRINGS_EMAIL_REQUIRED,
  STRINGS_LOGIN,
  STRINGS_PASSWORD,
  STRINGS_PASSWORD_REQUIRED,
} from "../../../../data/strings/Strings";
import {
  COLOR_BLACK,
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../../data/colors/Colors";
import useWindowDimensions from "../../../../core/utils/UseWindowDimensions";
import AutoHeight from "../../../component/AutoHeightView";
import { EmailRounded, LockRounded } from "@mui/icons-material";
import { CustomTextView } from "../../../component/CustomTextView";
import { CustomTextField } from "../../../component/CustomTextField";
import { useNavigate } from "react-router-dom";
import { ROUTE_ENDPOINTS } from "../../../../data/constant/RouteConstants";

interface LoginFormProps {
  onSubmit: (email: string, password: string) => void;
}

function LoginForm({ onSubmit }: LoginFormProps) {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleEmailChange = (email: string) => {
    setEmail(email);
  };

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors = validateInputs();
    setErrors(errors);

    if (Object.keys(errors).length === 0) onSubmit(email, password);
  };

  const validateInputs = () => {
    const errors: { [key: string]: string } = {};
    const emailValid = Validation.validateEmail(email);
    if (emailValid !== true) {
      errors.email = STRINGS_EMAIL_NOT_VALID;
    }
    if (email.length === 0) {
      errors.email = STRINGS_EMAIL_REQUIRED;
    }
    if (password.length === 0) {
      errors.password = STRINGS_PASSWORD_REQUIRED;
    }
    return errors;
  };

  const { width } = useWindowDimensions();

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Card
        elevation={0}
        sx={{
          flex: 1,
          padding: "8%",
          margin: "8%",
          borderRadius: 6,
          minWidth: "25vw",
          width: "100%",
          backgroundColor: width > 900 ? COLOR_LESS_BLACK : COLOR_BLACK,
          minHeight: "70vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack spacing={8} flex={1}>
          <CustomTextView
            text={STRINGS_LOGIN}
            variant="h3"
            textColor={COLOR_WHITE}
            fontWeight="bold"
          />

          <Stack spacing={2}>
            <AutoHeight>
              <CustomTextField
                type="email"
                placeholder={STRINGS_EMAIL}
                isFullWidth
                onTextChange={handleEmailChange}
                error={!!errors.email}
                helperText={errors.email}
                startIcon={<EmailRounded sx={{ color: COLOR_MAIN }} />}
                textColor={COLOR_WHITE}
                onHoverBorderColor={COLOR_LIGHT_GRAY}
                onFocusBorderColor={COLOR_LIGHT_GRAY}
                borderColor={COLOR_MAIN}
                backgroundColor={"transparent"}
              />
            </AutoHeight>
            <AutoHeight>
              <CustomTextField
                type="password"
                placeholder={STRINGS_PASSWORD}
                isFullWidth
                onTextChange={handlePasswordChange}
                error={!!errors.password}
                helperText={errors.password}
                startIcon={<LockRounded sx={{ color: COLOR_MAIN }} />}
                textColor={COLOR_WHITE}
                onHoverBorderColor={COLOR_LIGHT_GRAY}
                onFocusBorderColor={COLOR_LIGHT_GRAY}
                borderColor={COLOR_MAIN}
                backgroundColor={"transparent"}
              />
            </AutoHeight>
            <Button
              type="submit"
              variant="contained"
              sx={{
                borderRadius: 12,
                background: COLOR_MAIN,
                "&:hover": { background: COLOR_LIGHT_GRAY },
              }}
            >
              <CustomTextView
                text={STRINGS_LOGIN}
                variant="h6"
                props={{ p: 1, textTransform: "none" }}
                fontWeight="bold"
                textColor={COLOR_WHITE}
              />
            </Button>
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            spacing={1}
          >
            <CustomTextView
              text={"Don't have an account?"}
              fontSize={16}
              textColor={COLOR_LIGHT_GRAY}
            />
            <Button
              sx={{
                borderRadius: 12,
              }}
              onClick={() => navigate(ROUTE_ENDPOINTS.SIGNUP)}
            >
              <CustomTextView
                text={"Signup"}
                fontSize={18}
                props={{ textTransform: "none" }}
                fontWeight="bold"
                textColor={COLOR_MAIN}
              />
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
}

export default LoginForm;
