import { InputAdornment, SxProps, TextField } from "@mui/material";
import { ComponentRef, ReactElement } from "react";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import {
  COLOR_LIGHT_GRAY,
  COLOR_VERY_LIGHT_GRAY,
} from "../../data/colors/Colors";
import { Theme } from "@emotion/react";

interface props {
  type?: React.HTMLInputTypeAttribute;
  placeholder: string;
  variant?: "outlined" | "standard";
  onTextChange: (text: string) => void;
  isFullWidth?: boolean;
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  borderRadius?: number;
  textColor?: string;
  backgroundColor?: string;
  onHoverBorderColor?: string;
  borderColor?: string;
  onFocusBorderColor?: string;
  width?: number;
  height?: number;
  error?: boolean;
  helperText?: string;
  sxProps?: SxProps<Theme>;
  fontSize?: number;
  fontWeight?: string;
  value?: string;
  multiLine?: boolean;
  maxRows?: number;
  prefix?: string
}

export const CustomTextField: React.FC<props> = ({
  type = "text",
  variant = "outlined",
  placeholder,
  onTextChange,
  isFullWidth = true,
  startIcon,
  endIcon,
  value,
  multiLine = false,
  maxRows = 5,
  borderRadius = 12,
  textColor = COLOR_LIGHT_GRAY,
  backgroundColor = COLOR_VERY_LIGHT_GRAY,
  onHoverBorderColor = COLOR_LIGHT_GRAY,
  borderColor = COLOR_VERY_LIGHT_GRAY,
  onFocusBorderColor = COLOR_LIGHT_GRAY,
  width,
  height,
  error,
  helperText,
  sxProps,
  fontSize,
  fontWeight,
  prefix
}: props) => {
  return (
    <TextField
      type={type}
      value={value}
      prefix={prefix}
      placeholder={placeholder}
      variant={variant}
      onChange={(txt) => onTextChange(txt.currentTarget.value)}
      autoComplete="off"
      fullWidth={isFullWidth}
      multiline={multiLine}
      maxRows={maxRows}
      error={error}
      helperText={helperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{startIcon}</InputAdornment>
        ),
        endAdornment: <InputAdornment position="end">{endIcon}</InputAdornment>,
        sx: {
          borderRadius: borderRadius,
          fontFamily: MAIN_FONT_FAMILY,
          color: textColor,
          background: backgroundColor,
          width,
          height,
          fontWeight,
          fontSize,
        },
      }}
      sx={[
        {
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: onHoverBorderColor,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor,
            },
            "&.Mui-focused fieldset": {
              borderColor: onFocusBorderColor,
            },
          },
        },
        ...(Array.isArray(sxProps) ? sxProps : [sxProps]),
      ]}
    />
  );
};
