import {
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { Event } from "../../../domain/model/Event";
import { CustomCardView } from "../../component/CustomCardView";
import { CustomTextView } from "../../component/CustomTextView";
import {
  COLOR_BLACK,
  COLOR_LESS_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MAIN,
  COLOR_WHITE,
} from "../../../data/colors/Colors";
import {
  ArrowCircleRightRounded,
  DeleteOutlineRounded,
  PaymentsRounded,
  RateReviewOutlined,
  RateReviewRounded,
} from "@mui/icons-material";
import { ROUTE_ENDPOINTS } from "../../../data/constant/RouteConstants";
import Player from "../../component/CustomAudioPlayer";
import { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import {
  GOOGLE_MAPS_API_KEY,
  MAP_DARK_STYLE,
} from "../../../data/constant/Constants";
import useWindowDimensions from "../../../core/utils/UseWindowDimensions";
import moment from "moment";

interface p {
  event: Event;
  onDeleteClicked: (eventId: string) => void;
}

const NAME_MAX_LENGTH = 40;
const DESCRIPTION_MAX_LENGTH = 150;

export function EventsListItemView({ event, onDeleteClicked }: p) {
  const { width } = useWindowDimensions();

  const triggerDate = new Date(event.triggerDateString!);
  const eventFinished =
    event.finished || triggerDate.getTime() < new Date().getTime();

  const calculateTimeLeft = () => {
    const difference = +triggerDate - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    if (eventFinished) return;
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ["places"],
  });

  const remainingDays = (timeLeft as any).days;
  const remainingHours = (timeLeft as any).hours;
  const remainingMinutes = (timeLeft as any).minutes;
  const remainingSeconds = (timeLeft as any).seconds;

  const daysLeft = `${remainingDays < 10 ? "0" : ""}${remainingDays}`;
  const hoursLeft = `${remainingHours < 10 ? "0" : ""}${remainingHours}`;
  const minutesLeft = `${remainingMinutes < 10 ? "0" : ""}${remainingMinutes}`;
  const secondssLeft = `${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;

  const isSmallWidth = width < 900;

  const padding = 2.5;

  return (
    <Stack
      sx={{
        border: eventFinished ? "solid 1px " + COLOR_MAIN : undefined,
        borderRadius: 8,
      }}
    >
      <CustomCardView
        padding={0}
        background={eventFinished ? COLOR_BLACK : COLOR_MAIN}
        children={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack
                spacing={1}
                width={"100%"}
                height={"100%"}
                pl={padding}
                pt={padding}
                pr={!isSmallWidth ? 0 : padding}
                justifyContent={"space-between"}
              >
                <CustomTextView
                  text="Information"
                  variant="h5"
                  fontWeight="bold"
                  textColor={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                />
                <Stack>
                  <CustomTextView
                    text={`${event.name?.substring(
                      0,
                      event.name.length > NAME_MAX_LENGTH
                        ? NAME_MAX_LENGTH
                        : event.name.length
                    )} ${event.name!.length > NAME_MAX_LENGTH ? "..." : ""}`}
                    fontSize={24}
                    textColor={eventFinished ? COLOR_LIGHT_GRAY : COLOR_BLACK}
                  />

                  <CustomTextView
                    text={`${event.description?.substring(
                      0,
                      event.description.length > DESCRIPTION_MAX_LENGTH
                        ? DESCRIPTION_MAX_LENGTH
                        : event.description.length
                    )} ${
                      event.description!.length > DESCRIPTION_MAX_LENGTH
                        ? "..."
                        : ""
                    }`}
                    fontSize={18}
                    textColor={eventFinished ? COLOR_LIGHT_GRAY : COLOR_BLACK}
                  />
                  <CustomTextView
                    text={`created ${moment(event.createdAt).fromNow()}`}
                    fontSize={14}
                    textColor={COLOR_LIGHT_GRAY}
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Stack
                spacing={3}
                width={"100%"}
                alignItems={"center"}
                height={"100%"}
                justifyContent={"center"}
                pr={padding}
                pt={padding}
                pl={!isSmallWidth ? 0 : padding}
              >
                {eventFinished ? (
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <CustomTextView
                      text="Event finished"
                      fontSize={14}
                      textColor={COLOR_LIGHT_GRAY}
                    />
                    <CustomTextView
                      text={moment(triggerDate).fromNow()}
                      variant="h6"
                      textColor={COLOR_MAIN}
                      fontWeight="bold"
                    />
                  </Stack>
                ) : (
                  <>
                    <CustomTextView
                      text="Starting In"
                      variant="h5"
                      fontWeight="bold"
                      textColor={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                    />
                    <Stack
                      direction={"row"}
                      spacing={1}
                      alignItems={"center"}
                      width={"100%"}
                      justifyContent={"space-around"}
                    >
                      {Number(daysLeft) > 0 && (
                        <Stack alignItems={"center"}>
                          <CustomTextView
                            text={daysLeft}
                            fontWeight="bold"
                            variant="h4"
                            textColor={COLOR_LESS_BLACK}
                          />
                          <CustomTextView
                            text="days"
                            variant="h6"
                            textColor={COLOR_BLACK}
                          />
                        </Stack>
                      )}

                      <Stack alignItems={"center"}>
                        <CustomTextView
                          text={hoursLeft}
                          fontWeight="bold"
                          variant="h4"
                          textColor={COLOR_LESS_BLACK}
                        />
                        <CustomTextView
                          text="hours"
                          variant="h6"
                          textColor={COLOR_BLACK}
                        />
                      </Stack>

                      <Stack alignItems={"center"}>
                        <CustomTextView
                          text={minutesLeft}
                          fontWeight="bold"
                          variant="h4"
                          textColor={COLOR_LESS_BLACK}
                        />
                        <CustomTextView
                          text="mins"
                          variant="h6"
                          textColor={COLOR_BLACK}
                        />
                      </Stack>

                      <Stack alignItems={"center"}>
                        <CustomTextView
                          text={secondssLeft}
                          fontWeight="bold"
                          variant="h4"
                          textColor={COLOR_LESS_BLACK}
                        />
                        <CustomTextView
                          text="secs"
                          variant="h6"
                          textColor={COLOR_BLACK}
                        />
                      </Stack>
                    </Stack>
                  </>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Divider
                sx={{
                  background: eventFinished ? COLOR_MAIN : COLOR_BLACK,
                  height: 2,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={7}>
              <Stack
                spacing={2}
                pl={padding}
                pb={padding}
                pr={!isSmallWidth ? 0 : padding}
              >
                <CustomTextView
                  text="Location"
                  variant="h5"
                  fontWeight="bold"
                  textColor={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                />
                <Stack
                  spacing={1}
                  width={"100%"}
                  sx={{
                    border:
                      "solid 3px " + (eventFinished ? COLOR_MAIN : COLOR_BLACK),
                    borderRadius: 9,
                  }}
                >
                  {isLoaded ? (
                    <CustomCardView
                      padding={0}
                      height={180}
                      background={COLOR_BLACK}
                      children={
                        <GoogleMap
                          mapContainerStyle={{
                            width: "100%",
                            height: "100%",
                          }}
                          zoom={14}
                          clickableIcons={false}
                          center={{
                            lat: event.location!.latitude,
                            lng: event.location!.longitude,
                          }}
                          options={{
                            disableDefaultUI: false,
                            scaleControl: false,
                            rotateControl: false,
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            keyboardShortcuts: false,
                            styles: MAP_DARK_STYLE,
                            zoomControl: false,
                            gestureHandling: "none",
                            backgroundColor: COLOR_LESS_BLACK,
                          }}
                        >
                          <Marker
                            icon={{
                              url: require("../../../assets/images/event_pin.png"),
                              anchor: new google.maps.Point(17, 46),
                              scaledSize: new google.maps.Size(42, 42),
                            }}
                            position={{
                              lat: event.location!.latitude,
                              lng: event.location!.longitude,
                            }}
                          />
                        </GoogleMap>
                      }
                    />
                  ) : (
                    <Stack
                      width={"100%"}
                      height={180}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <CircularProgress sx={{ color: COLOR_LIGHT_GRAY }} />
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={5}>
              <Stack
                spacing={1}
                width={"100%"}
                height={"100%"}
                justifyContent={"space-between"}
                pr={padding}
                pb={padding}
                pl={!isSmallWidth ? 0 : padding}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  spacing={1}
                >
                  <CustomTextView
                    text="Details"
                    variant="h5"
                    fontWeight="bold"
                    textColor={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                  />
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(_) => onDeleteClicked(event._id!)}
                    >
                      <DeleteOutlineRounded
                        sx={{
                          color: "red",
                        }}
                        fontSize="large"
                      />
                    </IconButton>
                    {eventFinished && (
                      <IconButton sx={{ p: 0 }}>
                        <RateReviewOutlined
                          sx={{
                            color: eventFinished ? COLOR_MAIN : COLOR_BLACK,
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                    )}
                    {!eventFinished && (
                      <IconButton
                        sx={{ p: 0 }}
                        href={`${ROUTE_ENDPOINTS.HOME_EVENTS}/${event._id}`}
                      >
                        <ArrowCircleRightRounded
                          sx={{
                            color: eventFinished ? COLOR_MAIN : COLOR_BLACK,
                          }}
                          fontSize="large"
                        />
                      </IconButton>
                    )}
                  </Stack>
                </Stack>
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    width={"100%"}
                    spacing={1}
                  >
                    <CustomCardView
                      padding={1}
                      background={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                      children={
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <PaymentsRounded
                            sx={{
                              color: eventFinished ? COLOR_BLACK : COLOR_WHITE,
                            }}
                            fontSize="small"
                          />
                          <CustomTextView
                            text={!event.isPaid ? "free" : `\$${event.price}`}
                            fontSize={14}
                            textColor={
                              eventFinished ? COLOR_BLACK : COLOR_WHITE
                            }
                            fontWeight="bold"
                          />
                        </Stack>
                      }
                    />

                    <CustomCardView
                      padding={1}
                      background={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                      children={
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <RateReviewRounded
                            sx={{
                              color: eventFinished ? COLOR_BLACK : COLOR_WHITE,
                            }}
                            fontSize="small"
                          />
                          <CustomTextView
                            text={
                              !event.reviewEnabled
                                ? "no review"
                                : event.reviewPrivate
                                ? "private"
                                : "public"
                            }
                            fontSize={14}
                            textColor={
                              eventFinished ? COLOR_BLACK : COLOR_WHITE
                            }
                            fontWeight="bold"
                          />
                        </Stack>
                      }
                    />
                  </Stack>

                  <Player
                    url={event.audioUrl}
                    bgColor={eventFinished ? COLOR_MAIN : COLOR_BLACK}
                    fontSize={12}
                    iconSize={18}
                    color={eventFinished ? COLOR_BLACK : COLOR_WHITE}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        }
      />
    </Stack>
  );
}
