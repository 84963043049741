import { CompanyRepository } from "../../../../data/repository/company/CompanyRepository";
import { EventRepository } from "../../../../data/repository/event/EventRepository";
import { Message } from "../../../model/Auth";
import { Company, UpdateCompanyRequestModel } from "../../../model/Company";
import { Event } from "../../../model/Event";
import { ResponseState, State } from "../../../model/ResponseState";

export interface UpdateEventUseCase {
  invoke: (event: Event) => Promise<ResponseState<Message>>;
}

export class UpdateEventUseCaseImpl implements UpdateEventUseCase {
  private eventRepo: EventRepository;

  constructor(eventRepo: EventRepository) {
    this.eventRepo = eventRepo;
  }

  async invoke(event: Event): Promise<ResponseState<Message>> {
    try {
      let response = await this.eventRepo.update(event);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
