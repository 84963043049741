import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { MAIN_FONT_FAMILY } from "../../data/constant/Constants";
import { STRINGS_CANCEl, STRINGS_OK } from "../../data/strings/Strings";

interface AlertDialogControls {
  open: boolean;
  title: string;
  description: string;
  handleNegativeButtonClick: () => void;
  handlePositiveButtonClick: () => void;
}

export const CustomAlertDialog: React.FC<AlertDialogControls> = ({
  open,
  title,
  description,
  handleNegativeButtonClick,
  handlePositiveButtonClick,
}: AlertDialogControls) => {
  return (
    <Dialog
      open={open}
      onClose={handleNegativeButtonClick}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { borderRadius: 16 } }}
    >
      <DialogTitle id="alert-dialog-title" fontFamily={MAIN_FONT_FAMILY}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          fontFamily={MAIN_FONT_FAMILY}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleNegativeButtonClick}
          sx={{ fontFamily: MAIN_FONT_FAMILY }}
        >
          {STRINGS_CANCEl}
        </Button>
        <Button
          onClick={handlePositiveButtonClick}
          autoFocus
          sx={{ fontFamily: MAIN_FONT_FAMILY }}
        >
          {STRINGS_OK}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
