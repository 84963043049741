import NetworkApi from "../../data/network/NetworkApi";
import NetworkApiImpl from "../../data/network/NetworkApiImpl";
import { AuthRepository } from "../../data/repository/auth/AuthRepository";
import { AuthRepositoryImpl } from "../../data/repository/auth/AuthRepositoryImpl";
import { CompanyRepository } from "../../data/repository/company/CompanyRepository";
import { CompanyRepositoryImpl } from "../../data/repository/company/CompanyRepositoryImpl";
import { EventRepository } from "../../data/repository/event/EventRepository";
import { EventRepositoryImpl } from "../../data/repository/event/EventRepositoryImpl";
import { UploadFileRepository } from "../../data/repository/uploadFIle/UploadFileRepository";
import { UploadFileRepositoryImpl } from "../../data/repository/uploadFIle/UploadFileRepositoryImpl";
import {
  LoginUseCase,
  LoginUseCaseImpl,
} from "../../domain/usecase/auth/login/LoginUseCase";
import {
  SignupUseCase,
  SignupUseCaseImpl,
} from "../../domain/usecase/auth/signup/SignupUseCase";
import {
  CreateCompanyUseCase,
  CreateCompanyUseCaseImpl,
} from "../../domain/usecase/company/create/CreateUseCase";
import {
  GetAllCompaniesUseCase,
  GetAllCompaniesUseCaseImpl,
} from "../../domain/usecase/company/getAll/GetAllUseCase";
import {
  GetCompanyDetailsUseCase,
  GetCompanyDetailsUseCaseImpl,
} from "../../domain/usecase/company/getOne/GetOneUseCase";
import {
  UpdateCompanyUseCase,
  UpdateCompanyUseCaseImpl,
} from "../../domain/usecase/company/update/UpdateUseCase";
import {
  CreateEventUseCase,
  CreateEventUseCaseImpl,
} from "../../domain/usecase/event/create/CreateUseCase";
import {
  DeleteEventUseCase,
  DeleteEventUseCaseImpl,
} from "../../domain/usecase/event/delete/DeleteUseCase";
import {
  GetAllEventsUseCase,
  GetAllEventsUseCaseImpl,
} from "../../domain/usecase/event/getAll/GetAllUseCase";
import {
  GetEventUseCase,
  GetEventUseCaseImpl,
} from "../../domain/usecase/event/getOne/GetOneUseCase";
import {
  UpdateEventUseCase,
  UpdateEventUseCaseImpl,
} from "../../domain/usecase/event/update/UpdateUseCase";
import {
  UploadFileUseCase,
  UploadFileUseCaseImpl,
} from "../../domain/usecase/uploadFile/UploadFileUseCase";
import { provideApiBuilder } from "./NetworkModule";

function provideNetworkApi(): NetworkApi {
  return new NetworkApiImpl(provideApiBuilder());
}

// auth
function provideAuthRepository(): AuthRepository {
  return new AuthRepositoryImpl(provideNetworkApi());
}
export function provideLoginUseCase(): LoginUseCase {
  return new LoginUseCaseImpl(provideAuthRepository());
}
export function provideSignupUseCase(): SignupUseCase {
  return new SignupUseCaseImpl(provideAuthRepository());
}

// upload file
function provideUploadFileRepository(): UploadFileRepository {
  return new UploadFileRepositoryImpl(provideNetworkApi());
}
export function provideUploadFileUseCase(): UploadFileUseCase {
  return new UploadFileUseCaseImpl(provideUploadFileRepository());
}

// company
function provideCompanyRepository(): CompanyRepository {
  return new CompanyRepositoryImpl(provideNetworkApi());
}
export function provideCreateCompanyUseCase(): CreateCompanyUseCase {
  return new CreateCompanyUseCaseImpl(provideCompanyRepository());
}
export function provideGetAllCompaniesUseCase(): GetAllCompaniesUseCase {
  return new GetAllCompaniesUseCaseImpl(provideCompanyRepository());
}
export function provideGetCompanyDetailsUseCase(): GetCompanyDetailsUseCase {
  return new GetCompanyDetailsUseCaseImpl(provideCompanyRepository());
}
export function provideUpdateCompanyUseCase(): UpdateCompanyUseCase {
  return new UpdateCompanyUseCaseImpl(provideCompanyRepository());
}

// event
function provideEventRepository(): EventRepository {
  return new EventRepositoryImpl(provideNetworkApi());
}
export function provideCreateEventUseCase(): CreateEventUseCase {
  return new CreateEventUseCaseImpl(provideEventRepository());
}
export function provideGetAllEventsUseCase(): GetAllEventsUseCase {
  return new GetAllEventsUseCaseImpl(provideEventRepository());
}
export function provideGetEventUseCase(): GetEventUseCase {
  return new GetEventUseCaseImpl(provideEventRepository());
}
export function provideUpdateEventUseCase(): UpdateEventUseCase {
  return new UpdateEventUseCaseImpl(provideEventRepository());
}
export function provideDeleteEventUseCase(): DeleteEventUseCase {
  return new DeleteEventUseCaseImpl(provideEventRepository());
}
