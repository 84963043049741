import { FileModel } from "../../../domain/model/File";
import { Response } from "../../api/Response";
import NetworkApi from "../../network/NetworkApi";
import { UploadFileRepository } from "./UploadFileRepository";

export class UploadFileRepositoryImpl implements UploadFileRepository {
  apiService: NetworkApi;

  constructor(datasource: NetworkApi) {
    this.apiService = datasource;
  }
  uploadFile(fileFormData: FormData): Promise<Response<FileModel>> {
    return this.apiService.uploadFile(fileFormData);
  }
}
