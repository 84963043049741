import Router from "./presentation/routes/Router";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import '@fontsource/outfit/300.css';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/600.css';
import '@fontsource/outfit/700.css';
import NotistackWrapper from "./presentation/component/NotistackWrapper";

const theme = createTheme({
  typography: {
    fontFamily: 'Outfit',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NotistackWrapper>
        <Router />
      </NotistackWrapper>
    </ThemeProvider>
  );
}

export default App;
