import { AuthRepository } from "../../../data/repository/auth/AuthRepository";
import { UploadFileRepository } from "../../../data/repository/uploadFIle/UploadFileRepository";
import { AccessToken, Auth } from "../../model/Auth";
import { FileModel } from "../../model/File";
import { ResponseState, State } from "../../model/ResponseState";

export interface UploadFileUseCase {
  invoke: (fileFormData: FormData) => Promise<ResponseState<FileModel>>;
}

export class UploadFileUseCaseImpl implements UploadFileUseCase {
  private uploadFileRepository: UploadFileRepository;

  constructor(uploadFileRepository: UploadFileRepository) {
    this.uploadFileRepository = uploadFileRepository;
  }

  async invoke(fileFormData: FormData): Promise<ResponseState<FileModel>> {
    try {
      let response = await this.uploadFileRepository.uploadFile(fileFormData);
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
