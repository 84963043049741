import { CompanyRepository } from "../../../../data/repository/company/CompanyRepository";
import { EventRepository } from "../../../../data/repository/event/EventRepository";
import { Company } from "../../../model/Company";
import { Event } from "../../../model/Event";
import { ResponseState, State } from "../../../model/ResponseState";

export interface GetCompanyDetailsUseCase {
  invoke: () => Promise<ResponseState<Company>>;
}

export class GetCompanyDetailsUseCaseImpl implements GetCompanyDetailsUseCase {
  private companyRepo: CompanyRepository;

  constructor(companyRepo: CompanyRepository) {
    this.companyRepo = companyRepo;
  }

  async invoke(): Promise<ResponseState<Company>> {
    try {
      let response = await this.companyRepo.getOne();
      if (response.isSuccessful) {
        return {
          responseState: State.Success,
          data: response.body!,
        };
      }
      return { responseState: State.Fail, error: response.errorBody! };
    } catch (error) {
      return {
        responseState: State.Error,
        error: { message: String(error), statusCode: 500 },
      };
    }
  }
}
