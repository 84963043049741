import styled from "@emotion/styled";
import { alpha, Switch } from "@mui/material";
import { COLOR_BLACK, COLOR_MAIN } from "../../data/colors/Colors";

export const MainColoredSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: COLOR_BLACK,
    "&:hover": {
      backgroundColor: alpha(COLOR_BLACK, 0.3),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: COLOR_BLACK,
  },
}));
