import { Card } from "@mui/material";
import { ReactElement } from "react";
import { COLOR_MAIN, COLOR_VERY_LIGHT_GRAY } from "../../data/colors/Colors";

interface props {
  children: React.ReactElement;
  padding?: number;
  borderRadius?: number;
  background?: string;
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  width?: number | string;
  maxWidth?: number | string;
}

export function CustomCardView(props: props) {
  return (
    <Card
      elevation={0}
      sx={{
        background: props.background ?? COLOR_MAIN,
        p: props.padding ?? 2,
        borderRadius: props.borderRadius ?? 8,
        height: props.height,
        minHeight: props.minHeight,
        maxHeight: props.maxHeight,
        width: props.width,
        maxWidth: props.maxWidth
      }}
    >
      {props.children}
    </Card>
  );
}
