import { Button, Stack } from "@mui/material";
import { CustomCardView } from "../../component/CustomCardView";
import { CustomTextView } from "../../component/CustomTextView";
import { COLOR_LIGHT_GRAY, COLOR_WHITE } from "../../../data/colors/Colors";
import { CheckCircleRounded, CloseRounded } from "@mui/icons-material";
import { formatDate } from "../../../core/utils/Utils";
import { Company } from "../../../domain/model/Company";

const INFO_MAX_LENGTH = 240;

interface p {
  company: Company;
  index: number;
  onVerifyClicked: (id: string, isVerified: boolean) => void;
}
export function CompaniesListItemView(p: p) {
  const company = p.company;
  const index = p.index;
  return (
    <CustomCardView
      padding={3}
      children={
        <Stack spacing={1} direction={"row"} justifyContent={"space-between"}>
          <Stack spacing={1}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <CustomTextView
                text={`#${index + 1}`}
                fontWeight="bold"
                fontSize={20}
              />
              <CustomTextView
                text={company.name}
                fontWeight="bold"
                fontSize={24}
              />
              {company.isVerified ? (
                <CheckCircleRounded sx={{ color: "green" }} />
              ) : (
                <CloseRounded sx={{ color: "red" }} />
              )}
            </Stack>
            <CustomTextView
              text={company.user?.email}
              fontSize={20}
              fontWeight="500"
            />
            <CustomTextView
              text={`${company.info?.substring(
                0,
                company.info.length > INFO_MAX_LENGTH
                  ? INFO_MAX_LENGTH
                  : company.info.length
              )} ${company.info!.length > INFO_MAX_LENGTH ? "..." : ""}`}
              fontSize={16}
              fontWeight="300"
            />
            <Stack alignItems={"center"} spacing={1} direction={"row"}>
              <CustomTextView
                text="Joined at"
                textColor={COLOR_LIGHT_GRAY}
                fontWeight="300"
                textAlign="center"
                fontSize={14}
              />
              <CustomTextView
                text={formatDate(
                  company.createdAt ?? new Date(),
                  "DD MMM YYYY"
                )}
                textAlign="center"
                fontSize={14}
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Button
              sx={{
                background: !company.isVerified ? "green" : "red",
                borderRadius: 22,
              }}
              onClick={() =>
                p.onVerifyClicked(company._id!, company.isVerified!)
              }
            >
              <CustomTextView
                text={company.isVerified ? "Reject" : "Accept"}
                props={{ textTransform: "none" }}
                textColor={COLOR_WHITE}
                variant="h6"
                fontWeight="bold"
              />
            </Button>
          </Stack>
        </Stack>
      }
    />
  );
}
